<fnl-paper>
  <h3 class="header-title text-h3" style="margin-bottom: 16px;">New Journeys</h3>

  <section class="analysis-section">
    <div class="ananlysis-button-wrap" *ngIf="accountPlanSettings">
      <button
        fnlButton
        mat-flat-button
        type="button"
        color="primary"
        class="analysis-button"
        (click)="accountPlanGenerate()"
      >
        <mat-icon svgIcon="powerpoint" color="primary"></mat-icon>
        <span class="analysis-button-text">{{ accountPlanData?.buttonText }}</span>
      </button>
      <div class="text-body2 analysis-button-subtitle" style="color: var(--fnl-color-text);">{{ accountPlanData?.buttonDescription }}</div>
    </div>
    <div class="ananlysis-button-wrap" *ngIf="enableGtmJourneyLink">
      <button
        fnlButton
        mat-flat-button
        type="button"
        color="primary"
        [routerLink]="'/journeys/' + JourneyType.GetTheMeeting + '/account'"
        [queryParams]="journeyQueryParams"
        class="analysis-button"
      >
        <mat-icon svgIcon="mail-meeting"></mat-icon>
        <span class="analysis-button-text">{{ journeyTypesNames.GetTheMeeting }}</span>
      </button>
      <div class="text-body2 analysis-button-subtitle" style="color: var(--fnl-color-text)">Use AI to generate a custom sales email for your
        prospect
      </div>
    </div>
    <div class="ananlysis-button-wrap">
      <button
        fnlButton
        mat-flat-button
        type="button"
        color="primary"
        [routerLink]="'/journeys/' + JourneyType.PreparationForMeeting + '/account'"
        [queryParams]="journeyQueryParams"
        class="analysis-button"
      >
        <mat-icon svgIcon="powerpoint" color="primary"></mat-icon>
        <span class="analysis-button-text">{{ journeyTypesNames.PreparationForMeeting }}</span>
      </button>
      <div class="text-body2 analysis-button-subtitle" style="color: var(--fnl-color-text)">Use AI to generate a PowerPoint presentation for
        your prospect
      </div>
    </div>
  </section>
</fnl-paper>
