import { BaseItem, BaseValue, CustomAndVisible } from './common.model';
import {
  MetricGoalType,
  MetricsTrend,
  MetricsUnit,
  UnitsContainer,
} from './metric.model';
import { Kpi, KpiGroup, Kpis } from '@app/core/models/kpi.model';

export interface Po1Metric {
  metricUid: string;
  absoluteValueDisplayText?: string;
  relativeValueDisplayText?: string;
  kpiDescription?: string;
  metricGroupOrder: number;
  metricOrder: number;
  goal: MetricGoalType;
  units: UnitsContainer;
  isKey: boolean;
  trend: MetricsTrend;
  improvement?: number;
  po1?: number;
  kpis: Po1KPI[];
  kpisCount?: number;
  childItems?: Po1Metric[];
  kpiBySolution?: Record<string, Po1KPI[]>;
}

export interface Po1KPI extends CustomAndVisible {
  kpiUid: string;
  kpiName: string;
  kpiPo1Aggressive?: number;
  kpiPo1Conservative?: number;
  aggressiveImprovement?: number;
  conservativeImprovement?: number;
  childItems?: Po1KPI[];
  averageImprovement?: number;
  description?: string;
  solutionDescription?: string;
  impactNotes?: string[];
  hasBenchmarks?: boolean;
  fromSolutionUid?: string;
}

export interface KPIPO1ToImprove {
  uid: string;
  conservativeValue?: number;
  aggressiveValue?: number;
  benchmarks?: BenchmarksBase[];
}

export interface KPIPO1Value {
  uid: string;
  conservative?: number;
  conservativeValue?: number;
  aggressive?: number;
  aggressiveValue?: number;
  aggressiveImprovementValue?: number;
  conservativeImprovementValue?: number
}

export interface MetricPO1ToImprove {
  powerOfOneValue?: number;
  metricUid: string;
}

export interface MetricPO1Value extends BaseValue {
  improvement?: number;
  metricValue?: number;
}

export interface KPIFormulasData {
  kpiUid: string;
  benchmarks: BenchmarkValue[];
}

export interface BenchmarkValue extends BaseItem {
  description: string;
  median: number;
  firstQuartile: number;
  unit: MetricsUnit;
}

export type BenchmarksBase = BaseValue;

export function po1MetricsAsKpiGroups(po1Metrics: Po1Metric[]): KpiGroup[] {
  return po1Metrics
    .filter(it => it.kpis?.length > 0)
    .sort(finlisticsMetricOrder)
    .map(it => convertToKpiGroup(it));
}

function convertToKpiGroup(po1Metric: Po1Metric): KpiGroup {
  return {
    metricUid: po1Metric.metricUid,
    metricAbsoluteValueDisplayText: po1Metric.absoluteValueDisplayText,
    metricRelativeValueDisplayText: po1Metric.relativeValueDisplayText,
    kpis: deepConvertToKpis(po1Metric.kpis, null, 1)
  }
}

function deepConvertToKpis(po1Kpis: Po1KPI[], parent: Kpi, level: number): Kpi[] {
  return po1Kpis?.map(it => convertToKpiWithChildren(it, parent, level)) || [];
}

function convertToKpiWithChildren(po1Kpi: Po1KPI, parent: Kpi, level: number): Kpi {
  const kpi = {
    uid: po1Kpi.kpiUid,
    name: po1Kpi.kpiName,
    parent,
    level,
    description: po1Kpi.description,
    childItems: []
  }
  kpi.childItems = deepConvertToKpis(po1Kpi.childItems, kpi, level + 1);
  return kpi;
}

export function finlisticsMetricOrder(a: Po1Metric, b: Po1Metric): number {
  if (a.metricGroupOrder === b.metricGroupOrder) {
    return a.metricOrder - b.metricOrder;
  }

  return a.metricGroupOrder - b.metricGroupOrder;
}

const KPI_CUSTOM_ORDER = {
  [Kpis.InvestmentInAdvancedAnalytics]: 1,
  [Kpis.InvestmentInBlockchainsOrDistributedLedger]: 2,
  [Kpis.InvestmentInCloud]: 3,
  [Kpis.InvestmentInEdgeComputing]: 4,
  [Kpis.InvestmentInInternetOfThings]: 5,
  [Kpis.InvestmentInMachineLearningAndArtificialIntelligence]: 6,
  [Kpis.InvestmentInRoboticProcessAutomation]: 7,
}

export function conservativeImprovementKpiOrdinal(value: KPIPO1Value): number {
  const customOrder = KPI_CUSTOM_ORDER[value.uid];
  return customOrder !== undefined
    ? -100_000 - customOrder // custom order KPIs below other enabled KPIs but above disabled KPIs
    : value.conservativeValue != null && (value.conservativeValue !== 0 || value.aggressiveValue !== 0)
      ? value.conservativeValue // generally order by conservative improvement value
      : -100_500; // disabled KPIs go at the end of the list
}