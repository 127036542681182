import { Injectable } from '@angular/core';
import { CompanyPresentationBackData, PrivateCompanyPresentationBackData } from '@app/modules/common/presentation/presentation.model';
import { interval, Observable, Subscription, switchMap, tap } from 'rxjs';
import { UserService } from '@app/core/services/user.service';
import { User } from '@app/core/models/user.model';
import { CompanyService } from '@app/core/services/company.service';
import { PresentationService } from '@app/modules/common/presentation/presentation.service';
import { finalize, map, takeUntil } from 'rxjs/operators';
import {
  ReportData,
  ReportStatus, ReportStatusUtils,
  StorageReportData,
} from '@app/modules/usage-reports/usage-reports.model';
import { NotificationsService } from '@app/core/services/notifications.service';
import { LocalStorage } from '@app/core/utils/storage/interfaces/local-storage.interface';
import { add, isAfter } from 'date-fns';
import { timezoneCorrection } from '../utils/date.util';

@Injectable({
  providedIn: 'root',
})
export class CompanyPresentationDataService {
  private readonly _warnNotificationId = 'usage-warn';
  private readonly _successNotificationId = 'usage-success';
  private readonly _errorNotificationId = 'usage-error';
  private readonly _storageId = '_clientPresentations';
  private _currentUserId: string;

  private _checkSubscription: Subscription;

  constructor(
    private _userService: UserService,
    private _companyService: CompanyService,
    private _notificationsService: NotificationsService,
    private _localStorage: LocalStorage
  ) {}

  private get _combinedStorageId(): string {
    return `${this._storageId}${
      this._currentUserId ? `_${this._currentUserId}` : ''
    }`;
  }

  runPresentationGeneration(
    data: CompanyPresentationBackData,
    companyUid: string
  ): Observable<boolean> {
    return this._userService.getCurrentUser().pipe(
      tap((user: User) => (this._currentUserId = user.uid)),
      switchMap((user: User) =>
        this._companyService.runPresentationGeneration(
          data,
          user.clientUid,
          companyUid,
          PresentationService.blockCid
        )
      ),
      map((result: { status: ReportStatus; messageId: string }) => {
        const generationResult = ReportStatusUtils.isCompletable(result.status);

        if (generationResult) {
          this._saveStoragePresentation(result.messageId);
          this.checkFilesToDownload();
        } else {
          this._notificationsService.removeNotification(
            NotificationsService.generalError
          );
          this._notificationsService.addError({
            id: this._warnNotificationId,
            message: 'The presentation generation failed. Try again later.',
            removable: true,
            autoRemovable: true,
          });
        }
        return generationResult;
      })
    );
  }

  runPrivateCompaniesPresentationGeneration(
    data: PrivateCompanyPresentationBackData,
    companyUid: string
  ): Observable<boolean> {
    return this._userService.getCurrentUser().pipe(
      tap((user: User) => (this._currentUserId = user.uid)),
      switchMap((user: User) =>
        this._companyService.runPrivateCompaniesPresentationGeneration(
          data,
          user.clientUid,
          companyUid,
          PresentationService.blockCid
        )
      ),
      map((result: { status: ReportStatus; messageId: string }) => {
        const generationResult = ReportStatusUtils.isCompletable(result.status);

        if (generationResult) {
          this._saveStoragePresentation(result.messageId);
          this.checkFilesToDownload();
        } else {
          this._notificationsService.removeNotification(
            NotificationsService.generalError
          );
          this._notificationsService.addError({
            id: this._warnNotificationId,
            message: 'The presentation generation failed. Try again later.',
            removable: true,
            autoRemovable: true,
          });
        }
        return generationResult;
      })
    );
  }

  checkFilesToDownload(): void {
    const storageReports = this._getStorageReports();

    if (
      storageReports.length === 0 ||
      (this._checkSubscription != null && !this._checkSubscription.closed)
    ) {
      return;
    }

    this._notificationsService.addWarning({
      id: this._warnNotificationId,
      removable: true,
      message: 'The report is being generated.',
    });

    this._checkSubscription = interval(3000)
      .pipe(
        switchMap(() => {
          const reportsToCheck = this._getStorageReports().map(
            (item: StorageReportData) => item.messageId
          );

          return this._companyService.getFilesDownloadList(reportsToCheck).pipe(
            tap((list: ReportData[]) => {
              const reportsToDownload = list.filter(
                (item: ReportData) => ReportStatusUtils.isReady(item.status)
              );
              const failedReports = list.filter(
                (item: ReportData) => ReportStatusUtils.isFailure(item.status)
              );

              if (
                reportsToDownload.length + failedReports.length ===
                list.length
              ) {
                this._checkSubscription?.unsubscribe();
                this._checkSubscription = null;
              }

              failedReports
                .concat(reportsToDownload)
                .forEach((report: ReportData) =>
                  this._updateStorageReport({
                    messageId: report.messageId,
                    expireDate: new Date(),
                  })
                );

              if (failedReports.length > 0) {
                this._notificationsService.addError({
                  id: this._errorNotificationId,
                  message:
                    'The report generation failed. Try again later.',
                  removable: true,
                  autoRemovable: true,
                });
              }

              if (reportsToDownload.length > 0) {
                this._notificationsService.addSuccess({
                  id: this._successNotificationId,
                  message: 'The report has been generated successfully.',
                  removable: true,
                  autoRemovable: true,
                });
                setTimeout(() => {
                  this._companyService.downloadFiles(reportsToDownload);
                }, 1000);
              }
            })
          );
        }),
        finalize(() =>
          this._notificationsService.removeNotification(
            this._warnNotificationId
          )
        )
      )
      .subscribe();
  }

  setCurrentUser(userId: string): void {
    this._currentUserId = userId;
  }

  private _saveStoragePresentation(messageId: string): void {
    const storageData: StorageReportData[] = this._getStorageReports();

    if (
      storageData.find(
        (item: StorageReportData) => item.messageId === messageId
      ) == null
    ) {
      storageData.push({
        messageId,
        expireDate: add(new Date(), { hours: 1 }),
      });
    }

    this._localStorage.setItem(
      this._combinedStorageId,
      JSON.stringify(storageData)
    );
  }

  private _getStorageReports(): StorageReportData[] {
    let storageData: string | StorageReportData[] = this._localStorage.getItem(
      this._combinedStorageId
    );
    storageData =
      storageData != null
        ? (JSON.parse(storageData) as StorageReportData[]).map(
            (item: StorageReportData) => ({
              ...item,
              expireDate: timezoneCorrection(item.expireDate as string),
            })
          )
        : [];
    storageData = this._verifyExpiredStorageReports(storageData);

    return storageData;
  }

  private _updateStorageReport(
    reportToUpdate: Partial<StorageReportData>
  ): void {
    let storageData: StorageReportData[] = this._getStorageReports();

    let updated = false;
    storageData = storageData.map((item: StorageReportData) => {
      if (reportToUpdate.messageId === item.messageId) {
        updated = true;
        return {
          ...item,
          ...reportToUpdate,
        };
      }

      return item;
    });

    if (!updated) {
      storageData.push(reportToUpdate as StorageReportData);
    }

    this._localStorage.setItem(
      this._combinedStorageId,
      JSON.stringify(storageData)
    );
  }

  private _verifyExpiredStorageReports(
    reports: StorageReportData[]
  ): StorageReportData[] {
    return reports.filter((item: StorageReportData) =>
      isAfter(item.expireDate as Date, new Date())
    );
  }
}
